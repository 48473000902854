export default [
  {
    path: '/Video/:id?/:lid?',
    name: 'Video',
    component: () => import(/* webpackPrefetch: true */ '../views/course'),
  },
  //课程兼容新前端
  {
    path: '/lessonInfo',
    redirect: (to) => `/Video/${to.query.nmiids || to.query.id}`,
  },
  //课程兼容教练端
  {
    path: '/:c(c_details|c_share)/:id/:lid?',
    redirect: (to) =>
      `/Video/${[to.params.id, to.params.lid].filter((i) => i).join('/')}`,
  },
  {
    path: '/card/:id/:cid?',
    name: 'Card',
    component: () => import('@/views/course/Card'),
  },
  {
    path: '/Commenting/:id',
    name: 'Commenting',
    component: () => import('@/views/course/Commenting'),
  },
  {
    path: '/wish/:id',
    name: 'Wish',
    component: () => import('@/views/course/Wish'),
  },
  {
    //兼容老链接
    path: '/myInfo',
    redirect: '/My',
  },
  {
    path: '/My',
    name: 'My',
    component: () => import('@/views/my'),
  },
  {
    path: '/Curriculum',
    name: 'Curriculum',
    component: () => import('@/views/my/Curriculum'),
  },
  {
    path: '/Order',
    name: 'Order',
    component: () => import('@/views/my/Order'),
  },
  {
    path: '/Share',
    name: 'Share',
    component: () => import('@/views/my/Share'),
  },
  {
    path: '/Operatproposal',
    name: 'Operatproposal',
    component: () => import('@/views/my/Operatproposal'),
  },
  {
    path: '/Talentrecruitment',
    name: 'Talentrecruitment',
    component: () => import('@/views/my/Talentrecruitment'),
  },
  {
    path: '/Talentdetail',
    name: 'Talentdetail',
    component: () => import('@/views/my/Talentdetail'),
  },
  {
    path: '/Authentication',
    name: 'Authentication',
    component: () => import('@/views/my/Authentication'),
  },
  {
    path: '/Binding',
    name: 'Binding',
    component: () => import('@/views/my/Authentication/Binding'),
  },
  {
    path: '/Modify',
    name: 'Modify',
    component: () => import('@/views/my/Authentication/Modify'),
  },
  {
    path: '/line',
    name: 'Line',
    component: () => import('@/views/line'),
    meta: {
      title: '线路图下载',
    },
  },
  {
    path: '/linecard/:id',
    name: 'Linecard',
    component: () => import('@/views/line/Linecard'),
  },
  {
    path: '/Code',
    name: 'Code',
    component: () => import('@/views/Code'),
  },
  {
    path: '/update',
    name: 'Update',
    component: () => import('@/views/Update'),
  },
  {
    path: '/Map',
    name: 'Map',
    component: () => import('@/views/Map'),
  },
  {
    path: '/c_goods',
    name: 'Goods',
    component: () => import('@/views/goods'),
    meta: {
      title: '商品详情',
    },
  },
  {
    path: '/prod',
    name: 'Prod',
    component: () => import('@/views/products/lists.vue'),
  },
  {
    path: '/prod_detail/:id',
    name: 'ProdDetail',
    component: () => import('@/views/products/detail.vue'),
  },
  {
    path: '/prod_info/:order_sn',
    name: 'ProdInfo',
    component: () => import('@/views/products/info.vue'),
  },
  {
    path: '/prod_kf/:order_sn',
    name: 'ProdKf',
    component: () => import('@/views/products/kf.vue'),
  },
  {
    path: '/prod_order/:order_sn',
    name: 'ProdOrder',
    component: () => import('@/views/products/order.vue'),
  },
  {
    path: '/prod_oLst',
    name: 'ProdOLst',
    component: () => import('@/views/products/olist.vue'),
  },

  {
    path: '/',
    alias: '/Home',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/:catchAll*',
    redirect: '/',
  },
];
